.device-mobile {
  .bazaarvoice_ratings_n_reviews {
    .BVRRQuickTakeSection {
      display: none;
    }
  }
}

.customer-service-section {
  .customer-service-landing-block {
    a {
      bottom: 20px;
    }
  }
}

[class*='icon-'],
.icon,
.selectBox-arrow:before,
.site-nav .menu__link--lvl-1.menu__link--has-children:after,
.site-nav .menu__link--lvl-2.menu__link--has-children:after,
.site-utils__dropdown__close:after,
#cboxClose:before,
.slick-prev:before,
.slick-next:before,
input[type='checkbox'] ~ label:before,
input[type='checkbox'] ~ .label:before,
input[type='checkbox']:checked ~ label:before,
input[type='checkbox']:checked ~ .label:before,
input[type='radio'] ~ label:before,
input[type='radio'] ~ .label:before,
input[type='radio']:checked ~ label:before,
input[type='radio']:checked ~ .label:before,
.bobbis-kit .product-grid__item:nth-child(1):after,
.bobbis-kit .product-grid__item:nth-child(2):after,
.responsive-tabs.responsive-tabs--how_to_use .resp-arrow:before,
.quickshop-wrapper.active:before,
.quickshop__close:before,
.foundation-finder__find-match:after,
.foundation-finder__hide:after,
.foundation-finder__quiz:before,
.foundation-finder__chat__copy:before,
.product-palette:before,
.product-sku-quickview__inner:before,
.offer-specific__container .offer__breadcrumb-link:before,
.makeup-lesson__step .icon-arrow:before,
.artist-picks-page__products .recommended-actions a:before,
.chat-history-page__products .recommended-actions a:before,
.favorites-mylists__lists-create-button:before,
.favorite-actions__link--add:before,
.favorite-actions__link--remove:before,
.my-lists__list-link--print:before,
.my-lists__list-link--email:before,
.giftcard__guides-header:before {
  text-rendering: auto !important;
}

.artist-picks-page .transcript__header.expanded a.view-details-button:before,
.chat-history-page .transcript__header.expanded a.view-details-button:before {
  text-rendering: auto !important;
}

.artist-picks-page .transcript__header--view-details .view-details-button:before,
.chat-history-page .transcript__header--view-details .view-details-button:before {
  text-rendering: auto !important;
}

.artist-picks-page .transcript.expanded a.view-details-button:before,
.artist-picks-page .recommended-products__category.expanded a.view-details-button:before,
.chat-history-page .transcript.expanded a.view-details-button:before,
.chat-history-page .recommended-products__category.expanded a.view-details-button:before {
  text-rendering: auto !important;
}

.artist-picks-page
  .transcript
  .recommended-products__header--view-details
  .view-details-button:before,
.artist-picks-page
  .recommended-products__category
  .recommended-products__header--view-details
  .view-details-button:before,
.chat-history-page
  .transcript
  .recommended-products__header--view-details
  .view-details-button:before,
.chat-history-page
  .recommended-products__category
  .recommended-products__header--view-details
  .view-details-button:before {
  text-rendering: auto !important;
}

@media (min-width: 768px) {
  .my-lists__list-header-link:before {
    text-rendering: auto !important;
  }
  .my-lists__list-link--share:before {
    text-rendering: auto !important;
  }
  .past-purchases-data-item__item.view-details .view-details-button:before {
    text-rendering: auto !important;
  }
  .active .past-purchases-data-item__item.view-details .view-details-button:before {
    text-rendering: auto !important;
  }
}

@media (max-width: 767px) {
  .giftcard-balance__purchase:after {
    text-rendering: auto !important;
  }
  .giftcard__check-balance:after {
    text-rendering: auto !important;
  }
  .my-lists__list-header-link:after {
    text-rendering: auto !important;
  }
}

.site-footer__column .menu-reference .menu__item {
  &.first p {
    margin: 0 0 70px 0;
  }
  &.last p {
    margin: 0;
  }
}

.customer-service-landing-block a {
  position: relative;
}

.page-products-14460.device-mobile {
  .desktop-text {
    display: inline;
  }
  .mobile-text {
    display: none;
  }
}

.page-node-21808.device-mobile {
  .desktop-text {
    display: inline;
  }
}
/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'ใช่ ';
              visibility: visible;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'ไม่ใช่ ';
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: inline-block;
    }
  }
  .email_input {
    display: inline-block;
  }
  .waitlist-header {
    display: block;
    font-weight: bold;
    padding-bottom: 10px;
  }
}

.waitlist_thankyou_message {
  margin: 90px 60px;
  font-family: 'Brandon Text Bold';
}

.appt-book-page-header-bg {
  display: none;
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .appointment-select {
        table {
          td {
            font-size: 19px;
          }
        }
      }
    }
  }
}

.appt-book {
  .my-appointments {
    .appt-book-content-header {
      margin: 133px 0 28px;
    }
  }
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .registration__email-list {
          height: 50px;
          margin-bottom: 0;
          .registration__email-list-text {
            float: left;
          }
        }
      }
    }
  }
  &-page-header-bg {
    &.bg-img-mobile {
      display: none;
    }
  }
}

.product-brief {
  .product-brief__bottom-mobile {
    .product-brief {
      &__short-desc-link {
        width: 34%;
      }
    }
  }
}

.product-foundation-vto {
  &__btn-container {
    .see-my-perfect {
      margin-#{$rdirection}: -6%;
    }
  }
  &__container {
    .product-foundation-vto {
      &__mobile-ctrl {
        ul.shade-list-vto {
          .swatches--single {
            a.swatch {
              background-color: $color-white;
            }
          }
          &.perfect-shades {
            white-space: unset;
          }
          &.all-shades {
            span.vto-text {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  &__overlay {
    .vto-ios-browser-error {
      z-index: 10000;
    }
  }
}

.product-brief {
  &__bottom-mobile {
    .product-brief {
      &__add-to-bag {
        .product {
          &__notify-me {
            > li .button {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.postal_code_container {
  input {
    padding: 5px 30px;
  }
  label {
    margin-#{$ldirection}: 20px;
  }
  .icon-search {
    position: relative;
    #{$ldirection}: 0.5rem;
    top: -2.1rem;
  }
  .icon-arrow_down {
    position: relative;
    top: -2rem;
    float: $rdirection;
    #{$rdirection}: 10px;
  }
}
