.change-password {
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($medium-up) {
        position: absolute;
        #{$ldirection}: 335px;
        top: 31%;
        transform: translateY(-50%);
        width: 54%;
        border: 1px solid $color-gray;
        margin-#{$rdirection}: 3%;
      }
      @include breakpoint($medium-portrait-only) {
        #{$ldirection}: 232px;
        width: 70%;
        margin-#{$rdirection}: 2%;
      }
      @include breakpoint($ipad-pro-portrait-only) {
        width: 54%;
        #{$ldirection}: 217px;
      }
      &:before {
        @include breakpoint($medium-up) {
          content: '';
          position: absolute;
          top: 43%;
          #{$ldirection}: -6%;
          border: 5px solid transparent;
          border-#{$rdirection}-color: $color-gray;
          -ms-transform: rotate(0);
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
      }
      &-reset {
        bottom: auto;
        top: 50%;
        #{$ldirection}: 51%;
        @include breakpoint($medium-up) {
          width: 170px;
        }
        @include breakpoint($medium-portrait-only) {
          #{$ldirection}: 32%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          #{$ldirection}: 49%;
        }
      }
      &-confirm {
        bottom: auto;
        top: 50%;
        #{$ldirection}: 100%;
        margin-#{$ldirection}: 2%;
        @include breakpoint($medium-up) {
          width: 33%;
        }
      }
    }
  }
}

.change-password-gnav {
  .cms-password-field,
  .password-field {
    position: relative;
    &__info {
      border: 1px solid $color-gray-light;
      width: 188px;
      &::before {
        content: '';
        position: absolute;
        top: 133px;
        #{$rdirection}: 91%;
        border: 5px solid;
        border-color: transparent transparent $color-black transparent;
      }
    }
  }
}
