.intersitial-language {
  width: 760px;
  min-height: 600px;
  text-align: center;
  overflow: hidden;
  @include breakpoint($medium-down) {
    width: 100%;
  }
  &__section {
    &:first-child {
      @include swap_direction(margin, 0 0 30px);
      @include swap_direction(padding, 0 0 30px);
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 0 0 8px);
      }
    }
  }
  &__header {
    @include swap_direction(padding, 5px 0 10px);
    font-size: 32px;
    border: none;
    line-height: 30px;
    font-family: $bb-sans;
  }
  &__text {
    font-size: 15px;
    font-family: $brandon-text-light;
  }
  &__list {
    @include swap_direction(margin, 33px auto 10px);
    width: 92%;
    @include breakpoint($medium-down) {
      width: 100%;
    }
    &--item {
      float: left;
      width: 25%;
      font-size: 12px;
      font-family: $brandon-text-medium;
      text-transform: uppercase;
      @include swap_direction(padding, 0 15px 10px);
      img {
        @include swap_direction(margin, 0 auto 15px);
        display: block;
        height: 50px;
      }
      &:last-of-type {
        border: none;
      }
    }
  }
  &__link {
    clear: both;
    @include swap_direction(margin, 20px 0);
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 10px 0);
    }
    &--link {
      @include button--secondary;
      width: 95%;
      height: 50px;
      font-size: 18px;
      @include breakpoint($medium-up) {
        width: 40%;
        height: 35px;
        font-size: 14px;
      }
    }
  }
  &__subtext,
  &__subtext a {
    color: $color-gray-link;
    @include swap_direction(padding, 10px 0 0);
  }
}

.site-footer {
  .language-select {
    display: block;
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}

.mpp {
  .product-thumb--price {
    @include breakpoint($small-screen) {
      -webkit-box-align: center;
      -webkit-justify-content: center;
      line-height: initial;
      .old_price {
        width: 100%;
        display: inline-block;
      }
    }
  }
}
