.customer-service {
  .customer-service-quick-info {
    .customer-service-quick-info__list-item--chat {
      .customer-service-quick-info__link {
        margin-top: 10px;
        margin-left: 60px;
        span.icon {
          top: -10px;
        }
      }
    }
  }
}

.customer-service-section {
  div#cs_chat {
    display: block;
    a {
      position: relative;
      top: 8px;
      bottom: 0;
    }
  }
}

#footer_sticky {
  min-height: 65px;
}

#lpChat {
  .lp_radiobutton_wrapper {
    .lp_radio_button {
      label::before,
      label::after {
        display: none;
      }
    }
  }
}

.device-mobile {
  #cs_chat {
    display: block;
    a {
      letter-spacing: 1px;
    }
  }
  #footer_sticky {
    min-height: 104px;
  }
  #lpChat .lp_lpview_content select {
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
    appearance: menulist;
  }
}

#cboxContent {
  .site-email-sms-signup {
    padding: 0;
    width: 840px;
    background: none;
    &__required-note {
      text-align: $ldirection;
    }
    &__agree,
    &__submit {
      margin-top: 24px;
    }
  }
}

.front .elc_notice_popup_v1_modal .modal-header {
  border-bottom: none;
}

.front .elc_notice_popup_v1_modal .modal-footer {
  border-top: none;
}
