/* Order confirmation page */
.confirmation-panel {
  .social-login {
    padding-#{$ldirection}: 0;
  }
}
/* Account Profile Page */
.account__section {
  .social-info {
    &__connect {
      margin-top: 25px;
      .fb-login-button {
        span {
          padding-top: 5px;
        }
      }
    }
    a {
      padding: 0;
      border: none;
      border-bottom: 2px solid;
    }
  }
}
/* Review page */
#power_review_container {
  .social-login {
    input {
      #{$ldirection}: 42px;
      margin-top: 6px;
      @include breakpoint($medium-up) {
        #{$ldirection}: 50%;
      }
    }
  }
}
