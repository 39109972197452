.appointment-booking-schedule__overlay {
  .overlay-content-container {
    margin-top: -95px;
    width: 90%;
    margin: auto;
    left: 5%;
    top: 40%;
  }
}

.appt-book {
  #appointment-book-sections {
    .js-back-to-link,
    .js-start-over,
    #appt-book-book-submit,
    .js-appt-book-submit,
    .appointment-booking-review__account-signup-form fieldset,
    .appointment-booking-review__account-signup-form input,
    .signup__note-textarea {
      font-size: 12px !important;
    }
    .appt-book-content-header {
      .section-header {
        padding: 0;
      }
    }
    .appointment-booking-review__account-signup-form {
      .signup__mobile-prefix {
        width: 50px !important;
      }
      .signup__mobile-phone {
        padding-left: 45px !important;
      }
    }
    .confirmation {
      .confirmation-container {
        .appt-date-cal {
          .add-cal-link {
            padding-left: 10%;
            padding-right: 10%;
          }
        }
      }
    }
    .service-select {
      .book-now-bar {
        .next {
          width: 50% !important;
          padding-bottom: 30px !important;
        }
      }
    }
    .appointment-booking-review__services.row {
      .service__details {
        padding-right: 33%;
      }
    }
  }
  .appointment-booking-main-collection__header-content {
    .virtual__appointment--btn {
      margin: 10px;
    }
    .appointment-booking-main-collection__header--title {
      line-height: 24px;
    }
  }
}

.account {
  .appointments-page {
    &.account__section {
      margin-left: 2%;
      margin-right: 2%;
      .appt-book {
        .my-appointments {
          .appt-book-no-appts-content {
            .no-appts-content {
              #appt-book-btn {
                margin-top: 40px !important;
              }
            }
          }
          .appointment-container {
            .appointment-details {
              .your-lessons {
                text-align: $ldirection;
              }
            }
          }
        }
      }
    }
  }
}

.appointment-booking-schedule {
  &__day-time-container {
    table {
      display: inline-table;
      float: none !important;
      margin: 0 0.5% !important;
    }
  }
}

#past-appt-body {
  .zoom-meeting {
    display: none;
  }
}
