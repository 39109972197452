.account__section {
  .address-book-page__content {
    .messages {
      display: none;
    }
  }
}

.password-request-page {
  &__header {
    line-height: 32px;
  }
}

.password-sent-page {
  .sent-info {
    .sent-info__text {
      margin: 10px 0px;
    }
  }
}

.password-reset-page {
  .password-reset__item {
    margin: 10px;
    input {
      width: 100%;
    }
  }
  .password-reset__text {
    margin: 10px;
  }
  .password-reset__submit {
    margin-left: 10px;
  }
  @media screen and (max-width: 360px) {
    .adpl {
      input[type='password'] {
        & + label {
          overflow: -webkit-paged-x;
        }
      }
    }
  }
}

.shipping-billing {
  .address-form {
    &__header {
      margin-top: 10px;
    }
  }
  #address {
    .address-form__submit {
      margin: 10px;
    }
  }
}

#past_purchases {
  .past-purchases__content {
    margin: 40px 0;
    .outer-wrap {
      margin: 0 10px;
      .past-purchases__shopping {
        margin-left: 0;
      }
    }
  }
}

#registration {
  #registration-wrapper {
    padding-left: 5px;
  }
}

.favorites-page {
  .my-lists__list-link--add-all {
    width: 95%;
    right: 2%;
    text-align: center;
  }
}

.shipping-billing {
  .breadcrumb {
    padding-left: 10px;
  }
}

.address-book-page .address-book-page__content {
  .address-book {
    margin: 0px;
    width: auto;
    padding: 0px 10px;
    a.address-book__button {
      width: 100%;
    }
  }
  .payment-info {
    margin: 0px;
    width: auto;
    padding: 0px 10px;
  }
}

.order-details-page__content {
  .order-products-summary {
    .cart-item {
      &__qty {
        width: 60%;
        &-label {
          float: left;
          margin-right: 10px;
        }
      }
      &__total {
        float: right;
        margin-top: 10px;
      }
    }
  }
  .order-totals {
    td {
      text-align: right;
    }
  }
}

.sign-in-page {
  &__header {
    @include thfont-bold();
  }
}

.fade-in-skybox {
  background: url(/media/export/images/global/ajax-loader-skybox.gif) no-repeat center left;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  width: 40px;
  height: 20px;
  background-size: 100%;
  display: inline-block;
}
