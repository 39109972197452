.margin_top_bottom {
  margin: 0.8em 0;
}

a.selectBox {
  &.error {
    border: 1px solid $color-red;
  }
}

.error {
  color: $color-red;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.section-store-locator {
  .responsive-tabs--store-locator {
    .resp-tab-item:last-child {
      display: none;
    }
  }
}

.site-footer {
  .wrap {
    &.site-footer__wrap {
      .sticky-footer-new-chat {
        .sticky-footer-chat__link {
          bottom: 170px;
        }
      }
      .content {
        .sticky-back-to-top-new {
          bottom: 100px;
        }
      }
    }
  }
  .site-footer-contact__item {
    &--phone {
      width: 100%;
    }
    &--email {
      width: 100%;
    }
  }
  .site-footer__main {
    position: relative;
    border-bottom: 1px solid #cccccc;
    padding: 20px 0;
    margin-bottom: 20px;
    text-align: left;
    .site-footer__column:last-child {
      position: absolute;
      bottom: 8px;
      right: 0px;
      padding: 10px 20px 0 20px;
    }
    .locale-select,
    .country-select {
      margin-left: 20px;
    }
    .trustmark-logo {
      width: 75px;
    }
  }
  &__payment-image {
    img {
      width: 75px;
      margin-right: 20px;
    }
  }
}

body {
  .site-footer {
    .site-footer__main {
      .site-footer__column:last-child {
        position: relative;
      }
    }
  }
  .site-header__smart-menu {
    > a {
      min-width: 21%;
    }
  }
}

.lp_pagination-area {
  .lp_buttons_area {
    .lp_next_button,
    .lp_submit_button {
      background: $color-black !important;
    }
  }
}
