$color-tamarillo: #a81010;

@mixin bg-position($x, $y) {
  @if $text-direction == ltr {
    background-position: $x $y;
  } @else {
    background-position: 100% - $x $y;
  }
}

.info-link {
  text-align: left;
}

.appt-book {
  #appointment-book-sections {
    .appointment-booking-location-select {
      .submit_geoloc__submit {
        width: calc(97% - 79px);
      }
    }
    label.error {
      color: $color-red;
      a {
        color: $color-red;
      }
    }
    .appointment-booking-review__account-signup-gdpr-compliant {
      .gdpr_compliant__info-right-icon {
        margin-left: 10px;
        overflow: hidden;
        width: 30px;
        height: 30px;
      }
      .gdpr_compliant__info-for-left,
      .gdpr_compliant__info-right-icon {
        float: left;
      }
    }
    .js-no-last-name {
      display: block !important;
      .signup__last-name {
        display: none;
      }
      .signup__first-name,
      .email-address {
        width: 100%;
      }
      .signup__appt_mobile_phone_separator {
        width: 5%;
        display: inline-block;
      }
      .signup__appt_mobile_part1,
      .signup__appt_mobile_part2 {
        width: 40%;
        display: inline-block;
      }
    }
    .appointment-booking-review__account-signup-form {
      clear: left;
    }
    .appointment-booking-review__error-messages .error-message,
    .appointment-booking-review__error-messages .error-message a {
      color: $color-tamarillo;
      margin: 0.5em 0;
      font-weight: bold;
    }
    .date-time .date-time__hour,
    #confirmation .appt-time {
      display: inline;
      word-spacing: -3px;
    }
    #confirmation {
      .appt-date {
        display: inline;
      }
    }
  }
}

.touchevents {
  .appt-book {
    .location-form {
      &__select {
        @include bg-position(97%, 46%);
      }
    }
  }
}

.appointment-booking-review__account {
  .js-book-appt-container {
    &.appointment-booking-review__account-signup {
      .appointment-booking-review__account-signup-form {
        .signup__email-list {
          &.js-registration-email-list {
            display: none;
          }
        }
      }
    }
  }
}
