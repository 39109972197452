.checkout {
  .recommended-products-panel {
    display: block;
    .recommended-products-panel__title {
      @include thfont-bold();
      font-size: 12px;
    }
    .recommended-item {
      .product_name a,
      .product_subname a,
      .cart-item__size,
      .shade,
      .formatted_price {
        font-size: 14px;
      }
      input[type='submit'] {
        padding: 0 5px;
        letter-spacing: normal;
        font-size: 14px;
      }
    }
    .recommended-products-panel__title:after,
    .recommended-products-panel__title:before {
      width: 47px;
    }
    .recommended-product-items {
      .recommended-item {
        img {
          width: auto;
        }
      }
      .cart-item__desc-info {
        min-height: 0;
        .product_name {
          min-height: 0;
          a {
            border: 0;
          }
        }
        .color {
          padding-bottom: 10px;
        }
      }
    }
  }
  .cross_sell_container {
    .cart_cross_sell_item {
      float: left;
      margin: 0.4em 1.2em 0.8em 0;
      position: relative;
      width: 100%;
      input[type='submit'] {
        font-size: 14px;
        padding: 10px 0px;
      }
    }
  }

  //Control directive to highlight checkout breadcrumb
  $checkout: (shipping, review, payment, confirm);
  @each $value in $checkout {
    &.#{$value} {
      .checkout-progress__#{$value} {
        font-weight: bold;
      }
    }
  }
  //Control directive to target order summary labels
  $order: (total, discount, subtotal, giftwrap, shipping, tax);
  @each $value in $order {
    .#{$value} {
      &.label {
        width: 55%;
      }
      &.value {
        width: 45%;
        float: right;
      }
    }
  }
  &.shipping,
  &.review {
    li[id='offer_bogo..'] {
      display: none;
    }
  }
}

.samples-page {
  .product {
    .samples-buttons,
    .sample-select-checkbox,
    .samples_prod_select {
      display: block !important;
      visibility: visible;
    }
    .samples_prod_select:hover {
      background-color: #000 !important;
      border-color: #000;
    }
  }
}

.mobile-breadcrumb {
  display: block;
  li {
    display: list-item;
  }
}

.viewcart {
  .checkout__sidebar {
    .order-summary-panel {
      display: block !important;
      visibility: visible;
    }
  }
}

ol.checkout-progress {
  li {
    float: left;
    list-style-type: decimal;
    width: 25% !important;
    margin: 0 10% 0 10% !important;
    line-height: 20px;
    padding: 5px 0px 5px 0px;
  }
}

.shipping {
  #address_form_container {
    border: 0px;
    padding: 0px;
  }
  select.error {
    border: 1px solid $color-red;
  }
}

.shipping-address {
  .delivery_instructions_container {
    textarea.error {
      border: 1px solid $color-red !important;
    }
  }
}

#shipping-panel {
  h3 {
    display: block;
    .title-txt {
      float: left;
    }
  }
  a.change_link {
    display: block;
    float: right;
  }
}

.cod-message {
  em {
    color: $color-red;
  }
}

.signin-wrapper {
  input[type='submit'].checkout_sign_in {
    width: 100%;
    margin-bottom: 20px;
  }
}

.checkout-section-head {
  text-align: center;
}

section {
  .checkout__sidebar {
    .content {
      .link {
        border: 0px;
      }
    }
  }
}

.touch {
  select {
    background: url('/media/export/cms/global/icon-dropdown.png') no-repeat center right 5px;
  }
}

.cart_overlay {
  .cart-products {
    .currency_type {
      display: none;
    }
  }
}

.pg_wrapper {
  .fade-in {
    background: url('/media/export/images/global/ajax-loader.gif') no-repeat center center #000000;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0.5;
  }
}

body#viewcart,
body#samples,
.body_container.js-checkout-signin,
body#index,
body#shipping,
body#billing,
body#review,
body#confirm {
  position: relative;
  .pg_wrapper,
  .checkout {
    position: static;
  }
  .continue-sticky {
    bottom: 0;
    position: fixed;
    line-height: 45px;
    height: 50px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 15px;
    z-index: 1;
    padding: 0;
    background-color: #ff4661;
  }
  .block-nodeblock-800,
  .block-nodeblock-816,
  .block-nodeblock-7202,
  .block-nodeblock-7206 {
    display: none;
  }
  .offer-code-panel {
    &__title {
      border-bottom: 0;
      padding-bottom: 0;
      .icon {
        position: absolute;
        top: 7px;
        right: 0px;
      }
      .icon-arrow_down {
        display: block;
      }
      .icon-arrow_up {
        display: none;
      }
      &.open {
        border-bottom: 1px solid $color-black;
        padding-bottom: 17px;
        .icon-arrow_down {
          display: none;
        }
        .icon-arrow_up {
          display: block;
        }
      }
    }
  }
}

body#viewcart {
  .continue-shopping {
    border: 0;
    float: left;
    text-align: left;
    width: auto;
  }
}

#viewcart,
#samples,
.body_container.js-checkout-signin,
#shipping,
#review,
#billing,
#confirm {
  .adpl {
    label {
      display: block !important;
      visibility: visible !important;
    }
    input[type='text'],
    input[type='password'],
    input[type='tel'],
    input[type='number'],
    input[type='email'] {
      margin-bottom: 0px;
    }
    .form-item,
    .form_element {
      margin-bottom: 1em;
    }
    select,
    .selectBox {
      @include swap_direction(padding, 0.5em 1em);
      height: 3em;
      line-height: 14px;
    }
    .required_mark {
      float: left;
      margin-right: 3px;
    }
  }
  .checkout-progress-bar {
    &__list {
      @include swap_direction(margin, 0);
      @include swap_direction(padding, 0);
      overflow: hidden;
      counter-reset: step;
      width: 100%;
      margin-bottom: 15px;
    }
    &__list-item {
      color: $color-gray;
      width: 25%;
      float: $ldirection;
      position: relative;
      text-align: center;
      font-size: 12px;
      line-height: 15px;
      a {
        border-bottom: none;
        color: $color-gray-dark;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 12px;
        &:hover,
        &:visited {
          color: $color-gray-dark;
          text-decoration: none;
        }
      }
      &:before {
        @include swap_direction(margin, 0 auto 5px auto);
        @include border-radius(10px);
        @include box-shadow($color-white 0 0 0 10px);
        content: counter(step);
        counter-increment: step;
        width: 20px;
        line-height: 20px;
        font-size: 8px;
        display: block;
        color: $color-gray-dark;
        background: $color-gray-dark;
      }
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: $color-gray-dark;
        position: absolute;
        #{$ldirection}: -50%;
        top: 10px;
        z-index: -1;
      }
      &:first-child:after {
        content: none;
      }
      &--complete {
        color: $color-gray-dark;
        font-weight: bold;
        a {
          color: $color-gray-dark;
          &:hover,
          &:visited {
            color: $color-gray-dark;
          }
        }
        &:before {
          background: $color-gray-dark;
          color: $color-gray-dark;
        }
        &.active {
          a {
            color: $color-pink;
            &:hover,
            &:visited {
              color: $color-pink;
            }
          }
          &:before {
            background: $color-pink;
            color: $color-pink;
          }
        }
      }
    }
  }
  .site-header {
    .site-utils__promo,
    .site-utils__nav,
    .site-utils__menu {
      display: none;
    }
    .mobile-checkout-back {
      display: block !important;
      font-size: 39px;
      position: absolute;
      left: 12px;
      top: 10px;
    }
  }
  &.js-sticky-header {
    .site-header {
      .mobile-checkout-back {
        top: -3px;
      }
    }
  }
  .order-summary-wrapper {
    #links-panel {
      display: none;
    }
  }
}

body#confirm {
  .site-header {
    .mobile-checkout-back {
      display: none !important;
    }
  }
}

body#signin {
  .adpl {
    label {
      display: block !important;
      visibility: visible !important;
    }
    &.account__new-account,
    &.account__return-user {
      input[type='email'] {
        margin-top: 10px;
        display: block;
        width: 100%;
        margin-bottom: 0;
      }
      input[type='password'] {
        margin-bottom: 0;
      }
      .form-submit.checkout_sign_in {
        margin-top: 20px;
      }
    }
  }
}

.address-form {
  &__item {
    label.error {
      border: 0;
    }
  }
}
